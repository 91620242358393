(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-authentication/assets/javascripts/fetch-kambi-token.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-authentication/assets/javascripts/fetch-kambi-token.js');
"use strict";


const {
  jupiterFetch
} = svs.core;
const {
  getKambiChannel
} = svs.components.oddset.common.utils;
const getAuthorizationCode = async () => {
  const launchParametersRes = await svs.core.jupiter.callAsync({
    method: 'GET',
    path: "/oddset/1/launchparameters"
  });
  const {
    authorizationCode
  } = launchParametersRes.response;
  if (!authorizationCode) {
    throw new Error('Unable to fetch auth code via launchparameters.');
  }
  return authorizationCode;
};
const getCustomerId = async () => {
  var _customizedSettingsRe;
  const customizedSettingsRes = await svs.core.jupiter.callAsync({
    method: 'GET',
    path: '/player/2/customizedsettings'
  });
  const customerId = customizedSettingsRes === null || customizedSettingsRes === void 0 || (_customizedSettingsRe = customizedSettingsRes.response) === null || _customizedSettingsRe === void 0 || (_customizedSettingsRe = _customizedSettingsRe.customizedSettings) === null || _customizedSettingsRe === void 0 || (_customizedSettingsRe = _customizedSettingsRe.properties) === null || _customizedSettingsRe === void 0 ? void 0 : _customizedSettingsRe.customerId;
  if (!customerId) {
    throw new Error('Unable to fetch customerId via customizedSettings.');
  }
  return customerId;
};
const getTokenFromKambi = async (authorizationCode, customerId) => {
  const {
    kambiAuthApiUrl,
    customerIdPrefix
  } = svs.oddset.kambiAuthentication.data;
  const kambiLoginRes = await jupiterFetch.fetch("".concat(kambiAuthApiUrl, "/punter/login.json?lang=sv_SE"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json'
    },
    svsOptions: {
      disableTelemetry: true
    },
    body: JSON.stringify({
      punterId: "".concat(customerIdPrefix).concat(customerId),
      ticket: authorizationCode,
      channel: getKambiChannel(),
      market: 'SE',
      requestStreaming: true
    })
  });
  const kambiLoginResJson = await kambiLoginRes.json();
  if (!kambiLoginResJson) {
    throw new Error('No response from Kambis login API');
  }
  if (!kambiLoginResJson.token) {
    throw new Error("No token on response. Response: ".concat(JSON.stringify(kambiLoginResJson), "}"));
  }
  return {
    token: kambiLoginResJson.token,
    hashedPunterId: kambiLoginResJson.hashedPunterId
  };
};
const fetchKambiToken = async () => {
  const authorizationCode = await getAuthorizationCode();
  const customerId = await getCustomerId();
  const tokenObject = await getTokenFromKambi(authorizationCode, customerId);
  return tokenObject;
};
setGlobal('svs.components.oddset.kambiAuthentication.fetchKambiToken', fetchKambiToken);

 })(window);